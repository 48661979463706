import React from 'react';
import { ResponsiveContext, Grid } from 'grommet';
import OverlayCard from '../overlay-card/overlay-card';

const PlacesList = ({ places, PlacePreviewComponent, urlType }) => {
  const size = React.useContext(ResponsiveContext);
  if (!PlacePreviewComponent) {
    PlacePreviewComponent = OverlayCard;
  }

  return (
    <Grid
      gap="small"
      columns={{ count: size === 'small' ? 1 : 2, size: 'small' }}
    >
      {places &&
        places.map((place) => (
          <PlacePreviewComponent {...place} key={place.id} urlType={urlType} />
        ))}
    </Grid>
  );
};

export default PlacesList;
