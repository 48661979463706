import React from 'react';

import { buildImageObj, slugToUrl } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';

import { Anchor, Box, Heading } from 'grommet';

const OverlayCard = ({
  mainImage,
  title,
  slug,
  urlType,
  children,
  ratio = 9 / 16
}) => {
  const url = slugToUrl(slug.current, urlType);
  const imageUrl = imageUrlFor(buildImageObj(mainImage))
    .width(600)
    .height(Math.floor(ratio * 600))
    .fit('crop')
    .auto('format')
    .url();

  return (
    <Box>
      <Anchor href={url}>
        <Box
          height="30vh"
          justify="end"
          background={{
            image: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${imageUrl})`,
            size: 'cover',
            position: 'center'
          }}
        >
          <Box pad="small">
            <Heading level="3" margin="none">
              {title}
            </Heading>
          </Box>
        </Box>
      </Anchor>
      {children && (
        <Box pad="small" background={{ color: 'dark-2' }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default OverlayCard;
