import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import CenterColumn from '../components/center-column/center-column';
import PortableText from '../components/portable-text/portable-text';

import { Box } from 'grommet';
import PlacesList from '../components/places-list/places-list';
import Hero from '../components/hero/hero';
import Marker from '../components/marker/marker';
import Lede from '../components/lede/lede';

export const query = graphql`
  query ValheimWorldTemplateQuery($id: String!) {
    world: sanityValheimWorld(id: { eq: $id }) {
      id
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      shortDescription
      slug {
        current
      }
      mainImage {
        ...Image
      }
      places {
        id
        title
        mainImage {
          ...Image
        }
        slug {
          current
        }
      }
    }
  }
`;

const ValheimWorldPage = ({ data, pageContext }) => {
  const world = data?.world;
  return (
    <Layout>
      <Hero mainImage={world.mainImage} title={world.title}>
        <Marker text="Valheim World" />
      </Hero>
      <CenterColumn>
        <Lede text={world.shortDescription} />
        {world._rawBody && <PortableText blocks={world._rawBody} />}

        {world.places && (
          <Box margin={{ top: 'large' }}>
            <PlacesList places={world.places} urlType="valheimPlace" />
          </Box>
        )}
      </CenterColumn>
    </Layout>
  );
};

export default ValheimWorldPage;
